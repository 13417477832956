.Analyzer {
  
  h1 {
    margin: 20px 0 20px 0;
  }
  
  h3 {
    padding: 10px;
    margin-bottom: 0 !important;
    font-size: 14pt;
    background: #ececec;
    border-bottom: 1px solid #ececec;
  }
  
  .MuiDataGrid-toolbar {
    justify-content: flex-end;
    margin-top: -40px;
  }
}

.Analyzer__filter {
  .MuiGrid-container {
    padding: 10px; 
  }
}

.Analyzer__filter-header {
  background: #ececec;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Analyzer__filter-actions {
  display: flex;
  justify-content: flex-end;
  
  button {
    &:first-child {
      margin-right: 5px;
    }
  }

}

.Analyzer__results {
  margin-top: 20px;
  margin-bottom: 20px;
  
  h3 {
    padding: 10px;
  }
  
  thead, th {
    font-weight: bold;
  }
}

.Analyzer__results-actions {
  display: flex !important;
  flex-direction: row;
}

.Analyzer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  div {
    display: flex;
    
    &:first-child {
      margin-right: 5px;
    }
  }
}