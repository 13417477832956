.NavBar__Toolbar {
  display: flex;
  justify-content: space-between;
  
  a {
    text-decoration: none;
    color: white;
  }
}

.NavBar__menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  
  @media (max-width: 576px) {
    justify-content: center;
  }
  
}

