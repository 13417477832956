.MagicLinkSignIn {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.MagicLinkSignIn__header {
  background: #3f51b5;
  width: 100%;
  color: white;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.MagicLinkSignIn__content {
  border: 1px solid #3f51b5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.MagicLinkSignIn__content-alternative {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #3f51b5;
  margin-top: 10px;

  h6 {
    text-align: center;
    border-bottom: 1px solid #3f51b5;
    line-height: 0.1em;

    span {
      background:white;
      padding:0 10px;
    }
  }
}

.MagicLinkSignIn__content-form {
  padding: 0 10px 10px 10px;
}

.MagicLinkSignIn__content-header {
  text-align: center;
  padding-top: 10px;
}

.MagicLinkSignIn__content-alternative-actions {
  margin-top: 10px;
  margin-bottom: 10px;
}

.MagicLinkSignIn__content-form-btn{
  margin-top: 10px !important;
}