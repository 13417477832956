.Dashboard {  
  h1 {
    margin: 20px 0 20px 0;
  }
  
  thead, th {
    font-weight: bold;
  }
}

.Dashboard__filter {
  .MuiGrid-container {
    padding: 10px;
  }

  h3 {
    padding: 10px;
    margin-bottom: 0 !important;
    font-size: 14pt;
    background: #ececec;
    border-bottom: 1px solid #ececec;
  }
  
  margin-bottom: 20px;
}

.Dashboard__filter-header {
  background: #ececec;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Dashboard__filter-actions {
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 5px;
    }
  }

}

.Dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .Dashboard__yearPicker {
    display:inline !important;
    
    .MuiSelect-select {
      font-size: 2.4rem;
      line-height: 2.5rem;
      padding-top: 0px;

    }
  }

  .Dashboard__header-actions {
    display: flex;
    button {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}