.Settings {  

}

.Settings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h1 {
    margin: 20px 0 20px 0;
  }
}