.ProjectSettings {
  h2 {
    margin: 10px 0 10px 10px;
  }
}

.ProjectSettings__actions {
  display: flex;
  justify-content: flex-end;
  margin: 10px 5px 10px 0;
}

.ProjectSettings__table-action {
  div {
    width: auto;
  }

  display: flex !important;
  flex-direction: row;
}

.ProjectSettings__table-header {
  th {
    font-weight: bold !important;
  }
}