h3 {
  background: #ececec;
  font-size: 14pt;
  padding: 10px;
  margin: 0;
}

.dark h3 {
  background: #454545;
}

.dark .Dashboard__filter-header{
  background: #454545;
}

.dark .Analyzer__results h3 {
  background: #454545;
}

.dark .Dashboard__filter-header h3{
  background: #454545;
  border: 0;
}

.dark .Analyzer__filter-header{
  background: #454545;
}

.dark .Analyzer__filter-header h3{
  background: #454545;
  border: 0;
}

.dark .MuiDataGrid-root {
  color: white;
}

.dark .MuiDataGrid-footer {
  color: white !important;
}

.dark .MuiButton-outlinedPrimary {
  border-color: white;
  color: white;
}

.dark .MuiIconButton-colorPrimary {
  color: white;
}

.dark .MuiButton-textPrimary {
  color: white !important;
}

.dark .MuiButton-textPrimary {
  color: white;
}

.dark .MuiButton-textPrimary {
  color: white;
}

.dark > .MuiButton-textPrimary {
  color: white;
}

.dark {
  color: white;
}

.dark button{
  color: white;
}


html, body, #root {
  height: 100%;
}